import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { YesNoEnumDisplay } from 'enums/YesNoEnum';
import LocationBreadcrumbs from 'features/inventory/components/LocationBreadcrumbs/LocationBreadcrumbs';
import LocationInventoryTable from 'features/inventory/components/LocationInventoryTable/LocationInventoryTable';
import LocationsTable from 'features/inventory/components/LocationsTable/LocationsTable';
import { useInventoryLocationPath } from 'features/inventory/hooks/useInventoryLocationPath';
import inventoryApi from 'features/inventory/inventory.api';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';
import './LocationsPage.scss';

export default function LocationsPage() {
    usePageTitle('Locations');
    const { locationId = '' } = useParams();

    // Refresh relevant data when the page is loaded
    const refreshData = useApiTagInvalidate([ApiTagType.Location], { onMount: true });

    const query = inventoryApi.useLocationListQuery();

    const childLocations = useMemo(
        () =>
            query.data?.data.filter(l =>
                locationId ? l.parentLocationId === locationId : !l.parentLocationId,
            ),
        [query.data?.data, locationId],
    );

    const { model, path } = useInventoryLocationPath(locationId, query.data?.data ?? []);

    return (
        <div className="LocationsPage">
            <PageHeader
                className="LocationsPage__PageHeader"
                title="Locations"
                subtitle={
                    locationId ? (
                        <LocationBreadcrumbs
                            path={path}
                            model={model}
                        />
                    ) : null
                }
            />

            {locationId && (
                <div className="LocationsPage__Panel">
                    <PropertyContainer layout="row">
                        <PropertyDisplay
                            label="Type"
                            value={model?.locationTypeDisplay}
                        />
                        <PropertyDisplay
                            label="Can store"
                            value={YesNoEnumDisplay.display(model?.canStore)}
                        />
                        <PropertyDisplay
                            label="Can allocate"
                            value={YesNoEnumDisplay.display(model?.canAllocate)}
                        />
                    </PropertyContainer>

                    {/* User defined fields */}
                    {(query.data?.schemaExtensions.udfs?.length ?? 0) > 0 && (
                        <PropertyContainer layout="row">
                            {query.data?.schemaExtensions.udfs?.map(udf => (
                                <PropertyDisplay
                                    key={udf.id}
                                    label={udf.name}
                                    hint="(user-defined)"
                                    value={model?.userDefinedFields[udf.uniqueKey]}
                                />
                            ))}
                        </PropertyContainer>
                    )}
                </div>
            )}

            {locationId && <h2 className="LocationsPage__TableHeader">Child Locations</h2>}
            <LocationsTable
                key={locationId}
                data={childLocations}
                isLoading={query.isLoading}
                isError={query.isError}
                onRefresh={refreshData}
                isRefreshing={query.isFetching}
            />

            {locationId && (
                <>
                    <h2 className="LocationsPage__TableHeader">Inventory</h2>
                    <LocationInventoryTable
                        key={locationId}
                        locationId={locationId}
                    />
                </>
            )}

            <Outlet />
        </div>
    );
}
