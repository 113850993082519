import DataTable, {
    ColumnBuilder,
    DataTableColumn,
    DataTableSortDirection,
} from 'components/DataTable/DataTable';
import './PackagesTable.scss';

import DataTablePaging from 'components/DataTable/DataTablePaging';
import DataTableCriteria, { CriteriaBuilder } from 'components/DataTableCriteria/DataTableCriteria';
import dispatchApi, { PackageListParams } from 'features/dispatch/dispatch.api';
import { Package } from 'features/dispatch/models/Package';
import { ShipmentEntity } from 'features/dispatch/models/ShipmentEntity';
import LocationDisplay from 'features/inventory/components/LocationDisplay/LocationDisplay';
import { useWarehouseOptions } from 'features/inventory/hooks/useWarehouseOptions';
import { selectPrimaryLocationId, setPrimaryLocationId } from 'features/inventory/inventory.slice';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import { useDataTableDynamicQuery } from 'hooks/useDataTableDynamicQuery';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ApiTagType } from 'services/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import ShipmentStatusBadge from '../ShipmentStatusBadge/ShipmentStatusBadge';

const COLUMN_DEFS = ColumnBuilder<Package>()
    .column({
        label: 'Package',
        key: 'tuid',
        getValue: item => item.tuid,
        isSortable: false,
    })
    .column({
        label: 'Description',
        key: 'description',
        isSortable: false,
        getValue: item => item.description,
    })
    .column({
        label: 'Location',
        key: 'locationId',
        render: item => (
            <LocationDisplay
                locationId={item.locationId}
                showPath={false}
            />
        ),
    })
    .column({
        label: 'Shipment',
        key: 'shipment',
        render: item => <ShipmentDisplay shipment={item.context.shipment} />,
    })
    .build();

export default function PackagesTable() {
    // Selected warehouse is handled by redux prop primaryLocationId in inventory slice
    const dispatch = useAppDispatch();
    const selectedWarehouseId = useAppSelector(selectPrimaryLocationId);
    const warehouseFilterOptions = useWarehouseOptions();

    const criteriaFields = useMemo(
        () =>
            CriteriaBuilder<PackageListParams>()
                .criteria({
                    field: 'search',
                    label: 'Search',
                    type: 'search',
                    defaultValue: '',
                })
                .criteria(
                    !!warehouseFilterOptions?.length && {
                        field: 'warehouseId',
                        label: 'Warehouse',
                        type: 'select',
                        options: warehouseFilterOptions,
                        defaultValue: selectedWarehouseId,
                    },
                )
                .criteria({
                    field: 'hideShipped',
                    label: 'Hide Shipped',
                    type: 'select',
                    options: [
                        {
                            label: 'Hide',
                            value: 'HIDE',
                        },
                        {
                            label: 'Show',
                            value: 'SHOW',
                        },
                    ],
                    defaultValue: 'HIDE',
                })
                .build(),
        [warehouseFilterOptions, selectedWarehouseId],
    );

    const { queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging } =
        useDataTableDynamicQuery<PackageListParams>(criteriaFields);

    const query = dispatchApi.usePackageListQuery(queryParams);

    const refreshData = useApiTagInvalidate([ApiTagType.Package]);

    const handleSortChanged = useCallback(
        (col: DataTableColumn<Package>, direction: DataTableSortDirection) => {
            setQuerySort({
                propertyKey: col.key,
                direction,
            });
        },
        [setQuerySort],
    );

    // Update primaryLocationId in redux when changing warehouse filter
    useEffect(() => {
        if (
            queryParams.criteria.warehouseId &&
            queryParams.criteria.warehouseId !== selectedWarehouseId
        ) {
            dispatch(setPrimaryLocationId(queryParams.criteria.warehouseId));
        }
    }, [dispatch, queryParams.criteria.warehouseId, selectedWarehouseId]);

    return (
        <div className="PackagesTable">
            <div className="PackagesTable__FilterBar">
                <DataTableCriteria
                    fields={criteriaFields}
                    onChange={setQueryCriteria}
                />
            </div>

            <DataTable
                className="PackagesTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={query.data?.data}
                onRefresh={refreshData}
                rowLinkTo={item => item.id}
                zebra="light"
                useStickyHeader={true}
                useFrontEndSorting={false}
                onSortChanged={handleSortChanged}
                isRefreshing={query.isFetching}
                columns={COLUMN_DEFS}
            />

            {(query.data?.total ?? 0) > 0 && (
                <DataTablePaging
                    data={paging}
                    totalCount={query.data?.total}
                    onChange={setQueryPaging}
                />
            )}
        </div>
    );
}

function ShipmentDisplay({ shipment }: { shipment?: ShipmentEntity }) {
    if (!shipment) {
        return null;
    }
    return (
        <div className="PackagesTable__ShipmentDisplay">
            <div className="top">
                <div className="tuid">{shipment.tuid}</div>
                <ShipmentStatusBadge
                    shipment={shipment}
                    size="tiny"
                />
            </div>
            <div className="name">{shipment.name}</div>
            <div className="date">{formatDateTimeRelative(shipment.date)}</div>
        </div>
    );
}
