import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import InventoryReceiptTable from 'features/inventory/components/InventoryReceiptTable/InventoryReceiptTable';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';
import './InventoryReceivingPage.scss';

export default function InventoryReceivingPage() {
    usePageTitle('Receiving');

    // Refresh relevant data when the page is loaded
    useApiTagInvalidate([ApiTagType.InventoryReceipt], { onMount: true });

    return (
        <>
            <PageHeader title="Receiving">
                <div className="InventoryReceivingPage__Actions">
                    <MyButton
                        buttonType="Accent"
                        size="medium"
                        label="New Inventory Receipt"
                        IconLeft={Icons.Plus}
                        href="add"
                        LinkComponent={MyButtonLink}
                    />
                </div>
            </PageHeader>

            <InventoryReceiptTable />

            <Outlet />
        </>
    );
}
