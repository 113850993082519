import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import inventoryApi from 'features/inventory/inventory.api';
import { LocationInventory } from 'features/inventory/models/LocationInventory';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React, { useState } from 'react';
import { ApiTagType } from 'services/api';
import './LocationInventoryTable.scss';

const FILTERS = FilterBuilder<LocationInventory>()
    .filter({
        label: 'Search',
        type: 'search',
        defaultValue: '',
        getFields: item => [item.context.inventory.partNumber, item.context.inventory.description],
    })
    .build();

const COLUMNS = ColumnBuilder<LocationInventory>()
    .column({
        label: 'Part No',
        key: 'partNumber',
        getValue: item => item.context.inventory.partNumber,
        isSortable: true,
    })
    .column({
        label: 'Description',
        key: 'description',
        getValue: item => item.context.inventory.description,
        isSortable: true,
    })
    .column({
        label: 'On Hand',
        key: 'quantityOnHand',
        getValue: item => item.quantityOnHand,
        isSortable: true,
        align: 'center',
    })
    .column({
        label: 'Allocated',
        key: 'quantityAllocated',
        getValue: item => item.quantityAllocated,
        isSortable: true,
        align: 'center',
    })
    .build();

export default function LocationInventoryTable({ locationId }: { locationId?: string }) {
    const query = inventoryApi.useLocationInventoryQuery(locationId || '', {
        skip: !locationId,
    });

    const refreshData = useApiTagInvalidate([ApiTagType.InventoryStock]);

    const [filteredData, setFilteredData] = useState<LocationInventory[]>();

    return (
        <div className="LocationInventoryTable">
            {(query.data?.length ?? 0) > 0 && (
                <div className="LocationInventoryTable__FilterBar">
                    <DataTableFilters
                        data={query.data}
                        filters={FILTERS}
                        onChange={setFilteredData}
                    />
                </div>
            )}
            <DataTable
                className="LocationInventoryTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={filteredData}
                onRefresh={refreshData}
                isRefreshing={query.isFetching}
                rowLinkTo={item => `/locations/${locationId}/inventory/${item.tenantInventoryId}`}
                zebra="light"
                useStickyHeader={true}
                useFrontEndSorting={true}
                columns={COLUMNS}
                emptyState="No inventory found"
            />
        </div>
    );
}
