import PageHeader from 'components/PageHeader/PageHeader';
import SalesOrdersTable from 'features/sales/components/SalesOrdersTable/SalesOrdersTable';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';
import './SalesPage.scss';

export default function SalesPage() {
    usePageTitle('Sales');

    // Refresh relevant data when the page is loaded
    useApiTagInvalidate([ApiTagType.SalesOrder], { onMount: true });

    return (
        <>
            <PageHeader title="Sales" />

            <SalesOrdersTable />

            <Outlet />
        </>
    );
}
