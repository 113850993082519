import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import ShipmentsTable from 'features/dispatch/components/ShipmentsTable/ShipmentsTable';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';

export default function ShipmentsPage() {
    usePageTitle('Shipments');

    // Refresh relevant data when the page is loaded
    useApiTagInvalidate([ApiTagType.Shipment], { onMount: true });

    return (
        <>
            <PageHeader title="Shipments">
                <MyButton
                    label="New Shipment"
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                    href="create"
                    LinkComponent={MyButtonLink}
                />
            </PageHeader>
            <ShipmentsTable />
            <Outlet />
        </>
    );
}
